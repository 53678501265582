import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Content, { HTMLContent } from '../components/Content';

import Footer from '../components/Footer';
import styled from 'styled-components';
import palm from '../../static/img/palm.jpg';

const PostContainer = styled.div``;

const ColumnNewsLetter = styled.div`
  padding: 74px 15px 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LinkSpan = styled.span`
  color: #1f1f1f;
  text-decoration: none;
  font-weight: 500;
`;

const ChevronComp = styled.i`
  line-height: 16px;
  padding-right: 10px;
  transform: scale(0.7);
  opacity: 0.3;
`;

const Title = styled.p`
  font-family: 'Merriweather', serif;
  padding-top: 2vh;
  padding-bottom: 1vh;
  font-size: 2.5em;
  color: #3a3a3a;
  margin-bottom: -4px;
  font-weight: 300;
`;

const Description = styled.p`
  font-family: 'Merriweather', serif;
  color: rgba(145, 144, 144, 0.46);
  font-weight: 300;
  font-size: 2em;
  max-width: 75%;
`;

const MainParagraph = styled.p`
  width: 70%;
  margin: 0 auto;
  font-family: 'Lato', sans-serif;
  line-height: 1.9em;
  font-size: 1.2em;
  font-weight: 300;
`;

const Date = styled.p`
  color: rgba(145, 144, 144, 0.46);
  padding-bottom: 2vw;
  margin: 0 auto;
  width: 70%;
`;

const ImageContainer = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  padding: 0 15px;
  width: 100%;
  height: 100%;
  margin: 0;
`;

const ImageGroup = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
`;

const ImageObjectContainer = styled.img`
  width: 540px;
  height: 760px;
  object-fit: cover;
  object-position: center top;
`;

export const BlogPostTemplate = ({
  content,
  contentComponent,
  date,
  description,
  tags,
  title,
  helmet,
  image,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="EventViewerWrapper">
      {/* Not sure about this one */}
      {helmet || ''}
      <PostContainer>
        <div
          style={{
            overflow: 'hidden',
            zIndex: '-1',
            backgroundColor: 'white',
          }}
        >
          <div className="container newsLetterContainer">
            <div className="row rowContainerNewsLetter">
              <ColumnNewsLetter className="col-md-6 columnNewsLette1">
                <div style={{ paddingTop: '100px' }}>
                  <ol
                    className="breadcrumb"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    style={{ backgroundColor: 'white', paddingBottom: '5vw' }}
                  >
                    <li className="breadcrumb-item">
                      {/* <a href="../news_events.html"> */}
                      <Link to="/#current_events_cms">
                        <LinkSpan>
                          <ChevronComp className="fa fa-chevron-left" />
                          Back
                        </LinkSpan>
                      </Link>
                      {/* </a> */}
                    </li>
                  </ol>
                  <Title
                    data-aos="fade-up"
                    data-aos-duration="250"
                    data-aos-once="true"
                  >
                    {title}
                    <br />
                  </Title>
                  <Description
                    data-aos="fade-up"
                    data-aos-duration="300"
                    data-aos-once="true"
                  >
                    {description}
                    <br />
                  </Description>
                </div>
              </ColumnNewsLetter>
              {/* Use default palm pic if no featured pic */}
              <ImageContainer
                className="col-md-6"
                id="newsLetterImageContainer"
              >
                <div className="newsLetterSubContainer">
                  <ImageGroup>
                    <ImageObjectContainer
                      className="newsLetterImage"
                      src={palm}
                      alt="Post Item"
                      width="100%"
                    />
                  </ImageGroup>
                </div>
              </ImageContainer>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: 'white' }}>
          <div className="container" style={{ padding: '7.5vw 0px' }}>
            <div className="row">
              <div className="col-md-12">
                <Date>{date}</Date>
                <MainParagraph>
                  <PostContent content={content} />
                </MainParagraph>
              </div>
            </div>
          </div>
        </div>
      </PostContainer>
    </div>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  date: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <div>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        date={post.frontmatter.date}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Event">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
      <Footer />
    </div>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
